export const LAYOUT_DATA_MAP = [
  {
    // 猜你喜欢
    key: '1',
    value: 'uLikeData',
    limitNum: 50,
  },
  {
    // 同行都在选
    key: '3',
    value: 'chooseGoodsList',
    limitNum: 10,
  },
];

// 前端对应后端
export const CATEGORY_MAP = {
  '100025': 100197,
  '100001': 100254,
  '100077': 100268,
};

export const CATEGORY_MAP_REVERSE = {
  '100197': 100025,
  '100254': 100001,
  '100268': 100077,
};

