import type { BaseData, IObj } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { api } from '../../../utils';
import type { ICategory, IBusinessDistrict, ICategoryStyleList, IBusinessList } from '../interface';

export default class Store {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public allCategoryVisible = false; // 展示类目属性面板

  @observable public categoryName = '';

  @observable public categotyId: number = null;

  @observable public categoryChildren: IObj[] = [];

  @observable public allCategory: ICategory[] = []; // 所有分类

  @observable public businessCoreDistrict: IBusinessList[] = []; // 所有商圈

  @observable public businessDistrict: IBusinessDistrict[] = []; // 所有商圈

  @observable public categoryStyleList: ICategoryStyleList[] = []; // 所有商圈

  // 获取一级分类对应的风格
  @action public getCategoryStyle = (): void => {
    request<BaseData<ICategoryStyleList[]>>({
      url: `${api.getCategoryStyle}`,
      method: 'post',
      data: { ids: '100197,100254,100268' },
    }).then((res) => {
      this.categoryStyleList = res.data || [];
    });
  };

  // 获取品类导航
  @action public queryMoreCategory = (): void => {
    request<BaseData<ICategory[]>>({ url: `${api.queryMoreCategory}` }).then((res) => {
      this.allCategory = res.data || [];
    });
  };

  // 获取市场导航
  @action public queryBusinessDistrict = (): void => {
    request<BaseData<IBusinessDistrict[]>>({ url: `${api.queryBusinessDistrict}` }).then((res) => {
      this.businessDistrict = res.data || [];
    });
  };

  // 获取市场导航
  @action public queryCoreBusinessDistrict = (): void => {
    request<BaseData<IBusinessList[]>>({ url: `${api.queryCoreBusinessDistrict}` }).then((res) => {
      this.businessCoreDistrict = res.data || [];
    });
  };

  @action public toogleAllCategory = (allCategoryVisible: boolean, categotyId: number, categoryName?: string, categoryChildren?: IObj[], hiddenCatefury?: boolean): void => {
    Object.assign(this, {
      allCategoryVisible,
      categotyId,
      categoryName: categoryName || '',
      categoryChildren: categoryChildren || [],
    });
    if (allCategoryVisible && document.getElementById('allCategoryContentInfo')) {
      document.getElementById('allCategoryContentInfo').scrollTop = 0;
    }

    // 打开注释
    if (hiddenCatefury) {
      this.parent.headerStore.showCategory = false;
    }
  };
}
