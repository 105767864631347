/**
 * 首页：品类导航
 */
import { RightOutlined } from '@ant-design/icons';
import type { IObj } from 'egenie-utils';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import commonStyles from '../common.less';
import type { gotoSearchResultByConditon, ICategoryStyleList } from '../interface';
import styles from './index.less';
import type CatagoryNavStore from './store';

@observer
export default class CatagoryNav extends React.Component<{ store; catagoryNavStore: CatagoryNavStore; }> {
  constructor(props) {
    super(props);
  }

  render(): JSX.Element {
    const { gotoSearchResultByConditon } = this.props.store;
    const {
      allCategory,
      businessCoreDistrict,
      businessDistrict,
      categoryStyleList,
      categoryName,
      categotyId,
      categoryChildren,
      toogleAllCategory,
      allCategoryVisible,
      parent,
    } = this.props.catagoryNavStore;
    let timer;

    return (
      <div
        className={`${styles.catagoryNavWrap} ${window.location.pathname === '/egenie-ts-vogue/homePage/index' ? '' : styles.categoryNavFix} ${parent.headerStore.showCategory ? styles.showCategory : styles.hiddenCategory}`}
        onMouseEnter={() => {
          parent.headerStore.showCategory = true;
        }}
        onMouseLeave={() => {
          toogleAllCategory(false, null, '', [], true);
        }}
      >
        <div
          className={styles.contentWrap}
        >
          <div
            onMouseOut={() => {
              clearTimeout(timer);
            }}
            onMouseOver={() => {
              timer = setTimeout(() => {
                toogleAllCategory(true, null, '市场导航', businessDistrict, false);
              }, 200);
            }}
          >
            <div
              className={`${styles.contentHeader} ${commonStyles.fw500} ${commonStyles.fs12}`}
            >
              <span>
                <img
                  alt=""
                  className={styles.ftCategoryNameIcon}
                  src={`${PUBLIC_IMAGE_URL}marketIcon.png`}
                />
                <span>
                  市场导航
                </span>
              </span>
              <span>
                <RightOutlined style={{
                  fontSize: 8,
                  color: '#999BA4',
                }}
                />
              </span>
            </div>
            <div
              className={styles.contentInfo}
            >
              {
                businessCoreDistrict?.map(({
                  id,
                  name,
                }, indx) => (
                  <div
                    className={`${styles.catagoryName} ${commonStyles.fw400} ${commonStyles.fs12}`}
                    data-id={JSON.stringify({
                      name: '款式广场',
                      businessType: '市场',
                      categoryId: id,
                      categoryName: name,
                      jump: '/egenie-ts-vogue/searchResult/index',
                    })}
                    key={`coreBusiness${id}`}
                    onClick={(e) => {
                      gotoSearchResultByConditon(id, null, 'business', name);
                    }}
                  >
                    {name}
                  </div>
                ))
              }
              <div className={styles.line}/>
            </div>
          </div>
          <div className={styles.horizenLine}/>
          {
            allCategory && allCategory.length > 0 && allCategory.map((item, index) => (
              <CategoryItem
                gotoSearchResultByConditon={gotoSearchResultByConditon}
                item={item}
                key={`category${item.id}`}
                toogleAllCategory={toogleAllCategory}
              />
            ))
          }
        </div>
        <CategoryPanel
          allCategoryVisible={allCategoryVisible}
          categoryStyleList={categoryStyleList}
          child={categoryChildren}
          ftCategoryName={categoryName}
          gotoSearchResultByConditon={gotoSearchResultByConditon}
          id={categotyId}
          toogleAllCategory={toogleAllCategory}
        />
      </div>
    );
  }
}

const CategoryItem = observer((props) => {
  const {
    item,
    toogleAllCategory,
    gotoSearchResultByConditon,
  } = props;
  let timer = null;
  return (
    <div
      onMouseOut={() => {
        clearTimeout(timer);
      }}
      onMouseOver={() => {
        timer = setTimeout(() => {
          toogleAllCategory(true, item.id, item.ftCategoryName, item.child, false);
        }, 200);
      }}
    >
      <div
        className={`${styles.contentHeader} ${commonStyles.fw500} ${commonStyles.fs12}`}
      >
        <span>
          <img
            alt=""
            className={styles.ftCategoryNameIcon}
            src={`${PUBLIC_IMAGE_URL}${item.id}Icon.png`}
          />
          <span>
            {item.ftCategoryName}
          </span>
        </span>
        <span>
          <RightOutlined style={{
            fontSize: 8,
            color: '#999BA4',
          }}
          />
        </span>

      </div>
      <div
        className={styles.contentInfo}
      >
        {
          item?.currentCategory?.map((itemChild, indx) => (
            <div
              className={`${styles.catagoryName} ${commonStyles.fw400} ${commonStyles.fs12}`}
              data-id={JSON.stringify({
                name: '款式广场',
                businessType: '品类',
                categoryId: itemChild.id,
                categoryName: itemChild.ftCategoryName,
                jump: '/egenie-ts-vogue/searchResult/index',
              })}
              key={`currentCategory${itemChild.id}`}
              onClick={(e) => {
                gotoSearchResultByConditon(itemChild.id, item.id);
              }}
            >
              {itemChild.ftCategoryName}
            </div>
          ))
        }
        {/* <div className={styles.line}/> */}
      </div>
    </div>
  );
});

const CategoryPanel = observer((props: {
  id: number;
  ftCategoryName: string;
  child: IObj[];
  allCategoryVisible: boolean;
  gotoSearchResultByConditon: gotoSearchResultByConditon;
  toogleAllCategory;
  categoryStyleList?: ICategoryStyleList[];
}) => {
  const {
    id,
    ftCategoryName,
    child,
    gotoSearchResultByConditon,
    allCategoryVisible,
    toogleAllCategory,
    categoryStyleList,
  } = props;
  const categoryStyles = categoryStyleList?.find((v) => v.ftCategoryId === id)?.styles;

  // allCategoryVisible &&
  return allCategoryVisible && (
    <div
      className={`${styles.allCategoryWrap} fade-enter fade-enter-active fade`}
      onMouseLeave={() => {
        toogleAllCategory(false, null, '', []);
      }}
    >
      <div className={styles.lineLeft}/>
      <div
        className={styles.allCategory}
      >
        <div className={styles.ftCategoryName}>
          {ftCategoryName}
        </div>
        <div className={styles.allCategoryLine}/>
        <div className={styles.categoryAndStyleWrap}>
          <div className={styles.categoryStyleWrap}>
            {
              categoryStyles?.map((it) => {
                return (
                  <div
                    className={` ${styles.categogyStyleTxt}`}
                    key={it.name}
                    onClick={(e) => {
                      gotoSearchResultByConditon(it.name, id, 'nav');
                    }}
                    style={{ background: it.color }}
                  >
                    {it.name}
                    <i
                      className="icon-sq"
                      style={{ marginLeft: 0 }}
                    />
                  </div>
                );
              })
            }
          </div>
          <div
            className={styles.allCategoryContentInfo}
            id="allCategoryContentInfo"
          >
            {
              child?.map((itemChild, indx) => (
                <Fragment key={`panel${itemChild.id || itemChild.cityId}`}>
                  <div className={styles.categoryDetailName}>
                    {itemChild.cityName || itemChild.ftCategoryName}
                  </div>
                  <div>
                    {
                      (itemChild.child || itemChild.businessDistrictList)?.map((it) => {
                        const type = it.name ? 'business' : '';
                        return (
                          <div
                            className={`${styles.allCatagoryName} ${commonStyles.fw400} ${commonStyles.fs12}`}
                            key={`${type}${it.id}`}
                            onClick={(e) => {
                              gotoSearchResultByConditon(it.id, id, type, it.name);
                            }}
                            style={{ color: it.coreBusinessDistrict || it.currentSeason ? '#fb071f' : '#2B2E3E' }}
                          >
                            {it.ftCategoryName || it.name}
                          </div>
                        );
                      })
                    }
                  </div>
                </Fragment>

              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
});
