import { Modal } from 'antd';
import React from 'react';
import styles from './index.less';

export const IntroduceVideo = (props) => {
  const {
    videoVisible,
    operateVideoModal,
    videoUrl,
  } = props;
  return (
    <Modal
      centered
      className={styles.videoModalWrap}
      destroyOnClose
      footer={null}
      onCancel={() => {
        operateVideoModal(false);
      }}
      open={videoVisible}
      title=""
      width={1000}
    >
      <video
        autoPlay
        controls
        muted
        width="1000"
      >
        <source
          src={videoUrl}
          type="video/mp4"
        />
      </video>
    </Modal>
  );
};
