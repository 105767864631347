/**
 * 顶部搜索组件，包括款式和档口选择以及中间文字搜索和图片搜索
 */
import { CameraFilled, CheckOutlined, DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Input, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import { SEARCH_TYPE } from './constant';
import styles from './index.less';
import type { HeaderStore } from './store';

export const SearchItem = observer((props: { store: HeaderStore; }) => {
  const {
    store: {
      searchName,
      searchTypeKey,
      currentHotWord,
      visibleSearchType,
      showSearchPanel,
      searchHistoryList,
      handleSearchTypeClick,
      handleSearchTypeVisibleChange,
      changeInput,
      searchPro,
      gotoPage,
      toogleSearchPanel,
      handleSearch,
      clearSerchHistory,
    },
  } = props;

  const menuSearchType = (): MenuProps['items'] => {
    return SEARCH_TYPE.map((item) => {
      return {
        key: item.key,
        label: (
          <div
            className={`${searchTypeKey == item.key ? styles.itemActive : ''} ${styles.item}`}
            key={item.key}
            onClick={() => handleSearchTypeClick(item)}
          >
            <span>
              {item.value}
            </span>
            {searchTypeKey == item.key && (
              <CheckOutlined style={{
                color: '#1978FF',
                fontSize: 14,
              }}
              />
            )}
          </div>),
      };
    });
  };

  const searchType = SEARCH_TYPE.find((v) => v.key === searchTypeKey)?.value;
  return (
    <div className={styles.headerSearchWrap}>
      <Dropdown
        menu={{ items: menuSearchType() }}
        onOpenChange={handleSearchTypeVisibleChange}
        open={visibleSearchType}
        overlayClassName={styles.searchDropdown}
        trigger={['click']}
      >
        <a
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          {searchType}
          <DownOutlined style={{
            fontSize: 8,
            marginLeft: 4,
          }}
          />
        </a>
      </Dropdown>
      <Input
        allowClear
        className={styles.searchInput}
        onBlur={() => {
          toogleSearchPanel(false);
        }}
        onChange={changeInput}
        onClick={() => {
          toogleSearchPanel(true);
        }}
        onPressEnter={searchPro}
        placeholder={currentHotWord}
        value={searchName}
      />
      <Button
        className={styles.searchButton}
        onClick={searchPro}
        type="primary"
      >
        搜索
      </Button>
      {
        searchType === '款式' && (
          <Tooltip
            placement="bottomLeft"
            title="按图片搜索相似款"
          >
            <CameraFilled
              className={styles.camera}
              onClick={() => {
                const url = '/egenie-ts-vogue/searchResult/index?pageOrigin=searchImg&searchImg=true&searchImgUrl=&searchTypeKey=style';
                gotoPage(url);
              }}
            />
          </Tooltip>
        )
      }
      {(showSearchPanel && searchHistoryList.length > 0) && (
        <div className={`${styles.searchPanelWrap} fade-enter fade-enter-active fade`}>
          <div
            className={styles.historyWrap}
          >
            <div>
              历史搜索
            </div>
            <div
              className={styles.clear}
              onMouseDown={clearSerchHistory}
            >
              <img
                alt=""
                className={styles.clearIcon}
                src={`${PUBLIC_IMAGE_URL}clear.png`}
              />
              <span>
                清空
              </span>
            </div>
          </div>
          {
            searchHistoryList?.map((item, index) => {
              return (
                <div
                  className={styles.searchItem}
                  key={nanoid()}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    handleSearch(item);
                  }}
                >
                  {item}
                </div>
              );
            })
          }
        </div>
      )}
    </div>
  );
});

