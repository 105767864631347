import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { api, checkLoginStatus, scrollToAnchor } from '../../../utils';
import { TABS } from './constant';
import type { IOssConfig } from './interface';

export class HeaderStore {
  constructor(options) {
    const params = window.location.href.split('?')[1];
    const search = new URLSearchParams(params);
    this.parent = options.parent;
    this.tabKey = (search.get('tabKey') || 'homePage').split('#')[0];
    this.searchTypeKey = search.get('searchTypeKey') || 'style';
    this.searchName = search.get('searchName') || '';
    if (window.location.pathname !== '/egenie-ts-vogue/homePage/index') {
      const clothes = search.get('clothes');
      this.clothes = Number(clothes) || 100254;
    }
    this.getHotWords();
    this.checkFirstEntry();
    this.getSearchList();
    this.getNewAndExclusiveNum();
  }

  @observable public parent;

  @observable public timer: NodeJS.Timeout | null;

  @observable public visibleSearchType = false;

  @observable public searchName = '';

  @observable public loading = false;

  @observable public searchPic = '';

  @observable public hotSearchs: string[] = [];

  @observable public tabKey = '';

  @observable public searchTypeKey = 'style';// 搜索类型 style ｜ stall

  @observable public showSearchPanel = false;

  @observable public countDown = 0;

  @observable public searchHistoryList = [];

  @observable public clothes = 100254; // 默认女装

  // 阿里云配置信息
  @observable public ossConfig: IOssConfig = {
    accessKeyId: '',
    accessKeySecret: '',
    bucket: '',
    region: '',
    stsToken: '',
  };

  @observable public dir = ''; // 目录 上传图片用

  @observable public videoVisible = false;

  @observable public currentHotWord = '';

  @observable public showCategory = false;

  @observable public putAwayNum = 0;

  @observable public dynamicNum = 0;

  // 非首页hover导航展示品类信息，
  @action public onHoverNav = (): void => {
    if (window.location.pathname === '/egenie-ts-vogue/homePage/index') {
      this.showCategory = false;
      return;
    }
    this.showCategory = true;
  };

  @action public onLeaveNav = (): void => {
    if (window.location.pathname !== '/egenie-ts-vogue/homePage/index' && this.parent.catagoryNavStore?.allCategoryVisible) {
      return;
    }
    this.showCategory = false;
  };

  // 切换男装、女装、童装
  @action public changeClothes = async(key: number): Promise<void> => {
    scrollToAnchor('root');
    this.clothes = key;
    const loginStatus = await checkLoginStatus();
    if (loginStatus) {
      this.sendClothesType(key);
    }
    this.parent?.getFtCategory?.(this.clothes);
    this.parent?.getTabData?.();
    this.parent?.queryLiveGoods(this.clothes);
  };

  // 登录后，点击男装女装童装，发送请求，后端保存
  @action public sendClothesType = (key: number): void => {
    request<BaseData>({
      url: api.saveMainCategory,
      params: { categoryId: key },
    });
  };

  // 获取新品和专享推荐数量
  @action public getNewAndExclusiveNum = async(): Promise<void> => {
    const loginStatus = await checkLoginStatus();
    if (!loginStatus) {
      return;
    }
    this.getNewAndExclusiveNumFn('putAwayNum', api.getPutawayNum);
    this.getNewAndExclusiveNumFn('dynamicNum', api.getDynamicNum);
  };

  @action public getNewAndExclusiveNumFn = (type: string, url: string) => {
    // 获取新品和专享 数量
    request<BaseData<number>>({ url })
      .then((res) => {
        this[type] = res.data;
      })
      .catch(() => {
        Object.assign(this, {
          putAwayNum: 0,
          dynamicNum: 0,
        });
      });
  };

  // 获取搜索历史，如果是登录状态，接口返回，否则本地存储
  @action public getSearchList = async(): Promise<void> => {
    const loginStatus = await checkLoginStatus();
    if (loginStatus) {
      request<BaseData<string[]>>({
        url: api.getSearchHistory,
        method: 'post',
        data: {
          page: 1,
          pageSize: 10,
          sidx: 'id',
          sord: 'desc',
        },
      })
        .then((res) => {
          this.searchHistoryList = res.data || [];
        });
    } else {
      const searchHistoryList = window.localStorage.getItem('searchHistoryList');
      this.searchHistoryList = searchHistoryList ? JSON.parse(searchHistoryList) : [];
    }
  };

  // 记录搜索词
  @action public recordHistory = (value: string): void => {
    request<BaseData<string>>({
      url: api.recordHistory,
      method: 'post',
      data: { searchKey: value },
    });
  };

  // 首次进入自动播放视频并记录缓存
  @action public checkFirstEntry = (): void => {
    const PC_FIRST_LOGIN = window.localStorage.getItem('PC_FIRST_LOGIN');
    if (PC_FIRST_LOGIN) {
      return;
    }
    this.operateVideoModal(true);
    window.localStorage.setItem('PC_FIRST_LOGIN', 'YES');
  };

  // 获取嗖嗖热词
  @action public getHotWords = (): void => {
    request<BaseData<string[]>>({ url: api.getHotWords })
      .then((res) => {
        this.hotSearchs = res.data || [];
        if (res.data && res.data.length > 0) {
          this.currentHotWord = this.hotSearchs[0];
          this.countDown += 1;
          this.scrollHotWord();
        }
      });
  };

  // 循环显示热搜词
  @action public scrollHotWord = (): void => {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      if (!this.hotSearchs[this.countDown]) {
        this.countDown = 0;
      }
      this.currentHotWord = this.hotSearchs[this.countDown];
      this.countDown += 1;
    }, 5000);
  };

  @action public handleSearchTypeVisibleChange = (flag: boolean): void => {
    this.visibleSearchType = flag;
  };

  @action public handleSearchTypeClick = (e): void => {
    this.visibleSearchType = false;
    this.showSearchPanel = false;
    this.searchTypeKey = e.key;
    if (window.location.pathname === '/egenie-ts-vogue/searchResult/index') {
      this.parent.handleQuery?.();
    }
  };

  @action public changeInput = (e: { target: { value: string; }; }): void => {
    this.showSearchPanel = false;
    this.searchName = e.target.value;
  };

  // 判断是否搜索结果页
  @action public getSearchResultPage = () => {
    return window.location.pathname === '/egenie-ts-vogue/searchResult/index';
  };

  @action public selectHotSearch = (value: string): void => {
    this.showSearchPanel = false;
    this.getLocastoreHistorySearch(value);
    const url = `/egenie-ts-vogue/searchResult/index?searchName=${value}&searchTypeKey=style`;
    if (this.getSearchResultPage()) {
      this.refreshPage(url);
    } else {
      this.gotoPage(url);
    }
  };

  @action public changeTab = async(key: string): Promise<void> => {
    this.showSearchPanel = false;
    let url = TABS.find((v) => v.key === key)?.url;
    if (this.tabKey !== key) {
      if (key === 'exclusive') {
        const loginStatus = await checkLoginStatus();
        if (!loginStatus) {
          window.location.assign('/egenie-ts-vogue/login');
          return;
        }
        this.gotoPage(url);
      } else if (key === 'selectedActivities') {
        this.gotoPage(url);
      } else if (key === 'findStall') {
        window.open('/egenie-ts-vogue/findStall/index');
      } else if (key === 'newProduct' || key === 'peersChoose' || key === 'liveGoods') {
        url += `&clothes=${this.clothes}`;
        this.refreshPage(url);
      } else {
        this.refreshPage(url);
      }
    }
  };

  @action public refreshPage = (url) => {
    window.location.assign(url);
    scrollToAnchor('root');
  };

  @action public gotoPage = (url): void => {
    window.open(url);
  };

  @action public searchPro = (): void => {
    this.getLocastoreHistorySearch(this.searchName || this.currentHotWord);
    this.onSearch();
  };

  @action public onSearch = () => {
    this.showSearchPanel = false;

    // 如果就在搜索页，不会重新打开页面
    const url = `/egenie-ts-vogue/searchResult/index?pageOrigin=search&searchTypeKey=${this.searchTypeKey}&searchName=${this.searchName || this.currentHotWord}`;
    if (this.getSearchResultPage()) {
      this.parent?.topSearch();
    } else {
      this.gotoPage(url);
    }
  };

  @action public handleSearch = (value: string): void => {
    this.searchName = value;
    this.onSearch();
  };

  @action public operateVideoModal = (visible: boolean): void => {
    this.videoVisible = visible;
    this.showSearchPanel = false;
  };

  @action public toogleSearchPanel = (visible: boolean): void => {
    this.showSearchPanel = visible;
  };

  @action public getLocastoreHistorySearch = async(value: string): Promise<void> => {
    const loginStatus = await checkLoginStatus();
    if (!loginStatus) {
      const arr = window.localStorage.getItem('searchHistoryList');
      const searchList = arr ? JSON.parse(arr) : [];
      if (!searchList.find((v) => v === value)) {
        searchList.unshift(value);
        if (searchList.length > 10) {
          searchList.pop();
        }
      }
      window.localStorage.setItem('searchHistoryList', JSON.stringify(searchList));
    } else {
      this.recordHistory(value);
    }
    this.getSearchList();
  };

  @action public clearSerchHistory = async(): Promise<void> => {
    const loginStatus = await checkLoginStatus();
    if (loginStatus) {
      request<BaseData<string>>({ url: api.clearHistory });
    } else {
      window.localStorage.setItem('searchHistoryList', '');
    }
    this.getSearchList();
  };
}
