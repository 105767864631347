import { PUBLIC_IMAGE_URL } from '../../../utils';

export const SCROLL_TOP = 240;

export const TABS = [
  {
    key: 'homePage',
    label: '首页',
    url: '/egenie-ts-vogue/homePage/index?tabKey=homePage',
  },
  {
    key: 'liveGoods',
    label: '直播专区',
    url: '/egenie-ts-vogue/liveGoods/index?tabKey=liveGoods',
  },
  {
    key: 'newProduct',
    label: '新品',
    url: '/egenie-ts-vogue/newProducts/index?tabKey=newProduct',
  },
  {
    key: 'peersChoose',
    label: '同行都在选',
    url: '/egenie-ts-vogue/peersChoose/index?tabKey=peersChoose',
  },
  {
    key: 'exclusive',
    label: '专享推荐',
    url: '/egenie-ts-vogue/exclusive/index',

    // id: JSON.stringify({
    //   name: '款式广场',
    //   businessType: '专享推款',
    //   jump: '/egenie-ts-vogue/exclusive/index',
    // }),
  },
  {
    key: 'selectedActivities',
    label: '买手精选',
    url: '/egenie-ts-vogue/selectedActivities/index',
  },
  {
    key: 'findStall',
    label: '找档口/工厂',
    url: '/egenie-ts-vogue/findStall/index',
  },
];

export const SEARCH_TYPE = [
  {
    key: 'style',
    value: '款式',
  },
  {
    key: 'stall',
    value: '档口',
  },
];

export const VIDEO_URL = `${PUBLIC_IMAGE_URL}shj.mp4`;

export const CLOTHES_CLASSITION = [
  {
    label: '男装',
    value: 100197,
  },
  {
    label: '女装',
    value: 100254,
  },
  {
    label: '童装',
    value: 100268,
  },
];
