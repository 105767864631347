/**
 * 顶部搜索组件，包括左边logo，中间搜索，右边视频简介
 * 滑动到一定距离，要吸顶
 * 增加买手精选和找档口的tab，展示新品和专享数量
 * 增加男装女装童装选择和交互
 */
import { Spin, Tabs } from 'antd';
import { observer } from 'mobx-react';
import type { CSSProperties } from 'react';
import React, { useEffect } from 'react';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import { CLOTHES_CLASSITION, SCROLL_TOP, TABS, VIDEO_URL } from './constant';
import styles from './index.less';
import { IntroduceVideo } from './introduceVideo';
import { SearchItem } from './searchItem';
import type { HeaderStore } from './store';

// 首页-顶部搜索
export const HeaderSearch = observer((props: {
  store: HeaderStore;
  showTabs?: boolean;
  searchResult?: boolean;
  searchImg?: boolean;
  tabKey?: string;
  showTab?: boolean;
  showCategory?: boolean;
  showClothes?: boolean;
  videoStyle?: CSSProperties;
}) => {
  const {
    store: {
      loading,
      tabKey,
      hotSearchs,
      changeTab,
      selectHotSearch,
      handleSearchTypeVisibleChange,
      operateVideoModal,
      videoVisible,
      refreshPage,
      clothes,
      changeClothes,
      onHoverNav,
      onLeaveNav,
      putAwayNum,
      dynamicNum,
    },
    showTab = true,
    showCategory = true,
    showClothes = true,
    videoStyle,
  } = props;
  const [
    pageTop,
    setPageTop,
  ] = React.useState<number>(0);

  // 检测页面滑动距离顶部距离
  const getPageScrollTop = () => {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    setPageTop(scrollTop);
  };

  useEffect(() => {
    handleSearchTypeVisibleChange(false);
  }, [pageTop]);

  useEffect(() => {
    window?.addEventListener('scroll', getPageScrollTop);
    return () => {
      window.removeEventListener('scroll', getPageScrollTop);
    };
  }, []);
  return (
    <div className={styles.container}>
      <div
        className={`${styles.headerMainWrap} ${pageTop < SCROLL_TOP ? '' : styles.headerFixed}`}
      >
        <div
          className={styles.header}
          style={{ paddingTop: pageTop < SCROLL_TOP ? 21 : 12 }}
        >
          <div
            className={styles.headerLeft}
          >
            <div className={styles.headerLeftTop}>
              <img
                className={`${styles.shanHaiJinLogo} ${pageTop < SCROLL_TOP ? '' : styles.shanHaiJingTxtLogo}`}
                onClick={() => {
                  refreshPage('/egenie-ts-vogue/homePage/index');
                }}
                src={pageTop < SCROLL_TOP ? `${PUBLIC_IMAGE_URL}shjLogo.png` : `${PUBLIC_IMAGE_URL}shjTxtLogo.png`}
              />
              {showClothes && (
                <div className={styles.clothedWrap}>
                  {
                    CLOTHES_CLASSITION.map(({
                      label,
                      value,
                    }) => {
                      return (
                        <div
                          className={`${styles.clothes} ${clothes === value ? styles.selectedClothes : ''}`}
                          key={value}
                          onClick={() => {
                            changeClothes(value);
                          }}
                        >
                          {label}
                        </div>
                      );
                    })
                  }
                </div>
              )}
            </div>
            {(pageTop < SCROLL_TOP && showCategory) && (
              <div
                className={styles.headerLeftBottom}
                onMouseEnter={onHoverNav}
                onMouseLeave={onLeaveNav}
              >
                <img
                  className={styles.catagorynavImg}
                  src={`${PUBLIC_IMAGE_URL}categoryLogo.png`}
                />
                <span className={styles.headerTxt}>
                  导航
                </span>
              </div>
            )}
          </div>
          <div className={styles.headerCenter}>
            <SearchItem
              store={props.store}
            />
            {
              loading && (
                <div className={styles.uploadSpin}>
                  <Spin
                    size="small"
                    spinning={loading}
                  />
                  <span className={styles.uploadTxt}>
                    正在上传图片
                  </span>
                </div>
              )
            }
            {pageTop < SCROLL_TOP && (
              <div className={styles.hotSearchs}>
                热门搜索：
                {
                  hotSearchs.slice(0, 4)
                    .map((item, index) => (
                      <span
                        className={styles.hotWords}
                        key={item}
                        onClick={(e) => {
                          selectHotSearch(item);
                        }}
                      >
                        {item}
                        {index !== 3 && '，'}
                      </span>
                    ))
                }
              </div>
            )}
            {showTab && pageTop < SCROLL_TOP && (
              <div className={styles.headerTabWrap}>
                <Tabs
                  activeKey={tabKey}
                  items={TABS}
                  onChange={changeTab}
                />
                {putAwayNum > 0 && (
                  <div className={styles.newNum}>
                    {putAwayNum > 999 ? '999+' : putAwayNum}
                  </div>
                )}
                {dynamicNum > 0 && (
                  <div className={styles.exclusiveNum}>
                    {dynamicNum > 999 ? '999+' : dynamicNum}
                  </div>
                )}
              </div>
            )}
          </div>
          {pageTop < SCROLL_TOP && (
            <div
              className={styles.headerRight}
              onClick={() => {
                operateVideoModal(true);
              }}
              style={videoStyle}
            >
              <img
                alt=""
                className={styles.cloudDesc}
                src={`${PUBLIC_IMAGE_URL}cloudDesc.gif`}
              />
            </div>
          )}
        </div>
      </div>
      <IntroduceVideo
        operateVideoModal={operateVideoModal}
        videoUrl={VIDEO_URL}
        videoVisible={videoVisible}
      />
    </div>
  );
});

