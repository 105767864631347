// 新品 ｜ 同行都在选
import { Col, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ProductCard } from '../components';
import EmptyStatus from '../components/empty';
import { Filter } from '../components/filter';
import { Footer } from '../components/footer';
import { FooterLogo } from '../components/footerLogo';
import { HeaderSearch } from '../components/headerSearch';
import { Navigation } from '../components/navigation';
import { PaginationCus } from '../components/pagination';
import { RightSider } from '../components/rightSider';
import CatagoryNav from '../homePage/catagoryNav';
import styles from './index.less';
import Store from './store';

const store = new Store();

@observer
export default class NewProduct extends React.Component {
  constructor(props) {
    super(props);
  }

  render(): JSX.Element {
    const { loading, recommendGoodsList, headerStore, rightSiderStore, headerStore: { gotoPage }, paginationStore, totalPageCount, totalCount, filterParams } = store;
    return (
      <div
        className={styles.contentWrap}
        id="goodsListCommon"
      >
        <Navigation/>
        <HeaderSearch
          showTabs
          store={headerStore}

        />
        <div
          className={styles.recentlyNewWrap}
          style={{ height: (recommendGoodsList.length === 0) ? 'calc(100% - 410px)' : 'auto' }}
        >
          <CatagoryNav
            catagoryNavStore={store.catagoryNavStore}
            store={store}
          />
          <div className={styles.marketByStyle}>
            <Filter store={filterParams}/>
          </div>
          <Spin spinning={loading}>
            {recommendGoodsList && recommendGoodsList.length > 0 && (

              <Row
                className={styles.goodsWrap}
                gutter={[
                  12,
                  12,
                ]}
              >
                {
                  recommendGoodsList.map((item, index) => {
                    const { goodsId } = item;
                    return (
                      <Col
                        key={goodsId}
                      >
                        <ProductCard
                          onClick={() => {
                            gotoPage(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}`);
                          }}
                          {...item}
                          callback={() => {
                            rightSiderStore.getMyStyleWaitNum();
                          }}
                        />
                      </Col>
                    );
                  })
                }
              </Row>
            )}
          </Spin>
          {
            (!loading && recommendGoodsList.length === 0) && (
              <div className={styles.contentWrapEmpty}>
                <EmptyStatus/>
              </div>
            )
          }
          {
            recommendGoodsList?.length > 0 && (
              <PaginationCus
                store={paginationStore}
                totalCount={totalCount}
                totalPageCount={totalPageCount}
              />
            )
          }
        </div>
        <RightSider store={rightSiderStore}/>
        <FooterLogo/>
        <Footer/>
      </div>

    );
  }
}

